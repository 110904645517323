@charset "utf-8";
/* CSS Document */
* {
  box-sizing: border-box;
}

@font-face {
	font-family: Filmotype;
	src: url("./assets/fonts/Filmotype\ -\ FilmotypeLucky.otf");
}

/* For mobile phones: */
[class*="col-"] {
  float: left;
  padding: 0px;
  border: 0px solid red;
  width: 100%;
}

body {
	font-family: 'Quicksand';
	font-weight: 600;
	background-color: #fff;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	font-style: normal;
	color: #384241;
}

a:link {
	color:#68eee1;
	text-decoration: none;
}

a:active {
	color:#68eee1
}

a:visited {
	color:#68eee1;
}

a:hover {
	color:#ff7aac
}

/* Container */
.container {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	height: auto;
	background-color: #FFFFFF;
}

/* Navigation */

.logo {
	background: url("./assets/images/Kayfabe-site-logo.png");
	width: 75px;
	height: 49px;
}

.socialmedia-twitter {
	background: url("./assets/images/icon-twitter-dark.png");
	background-repeat: no-repeat;
	width: 28px;
	height: 26px;
	margin-left: 0px;
	margin-right: 15px;
}

.socialmedia-twitter:hover {
	background: url("./assets/images/icon-twitter.png");
	width: 28px;
	height: 26px;
}

.socialmedia-os {
	background: url("./assets/images/icon-os-dark.png");
	background-repeat: no-repeat;
	width: 28px;
	height: 26px;
	margin-left: 15px;
	margin-right: 0px;
}

.socialmedia-os:hover {
	background: url("./assets/images/icon-os.png");
	width: 28px;
	height: 26px;
}

.socialmedia-discord {
	background: url("./assets/images/icon-discord-dark.png");
	background-repeat: no-repeat;
	width: 28px;
	height: 26px;
	margin-left:15px;
	margin-right: 15px;
}

.socialmedia-discord:hover {
	background: url("./assets/images/icon-discord.png");
	width: 28px;
	height: 26px;
}

/* Ice Cream Truck Section */
.icecream-truck-section {
	background-color: #fff;
	background: url("./assets/images/truck-bg.png");
	background-size: cover;
	background-position: center;
	width: 100%;
	height: auto;
	display: block; 
    margin: 0 auto;
	background-repeat: repeat-x;
	padding-bottom: 25px;
}

.icecream-truck {
	display: flex;
    justify-content: center;
    align-items: center;
}

.icecream-truck-image {
	background: 
		url("./assets/images/truck-sm.png") 50% top;
	width: 100%;
	height: 404px;
	background-size: cover;
	background-repeat: no-repeat;
	margin-top: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.coming-soon-section {
	background: 
		url("./assets/images/truck-sm-comingsoon.png") 50% top;
	width: 100%;
	height: 404px;
	background-size: cover;
	background-repeat: no-repeat;
	margin-top: 0px;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.mint-section {
	width: 100%;
	height: 404px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 80px;
}

.minting-text {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 60px;
}

.minting-text-title {
	text-align: center;
	font-family: Filmotype;
	font-size: 30pt;
	color: #fff;
	filter: drop-shadow(3px 3px 0px #3a988f);
}

.minting-text-num {
	text-align: center;
	font-family: 'Bebas Neue';
	font-size: 20pt;
	color: #fccad6;
	filter: drop-shadow(3px 3px 0px #ff7aac);
}

.minting-text-price {
	text-align: center;
	font-family: 'Bebas Neue';
	font-size: 15pt;
	color: #fed97e;
}

.minting-inputs {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	padding-top: 20px;
}

.mint-num {
	text-align: left;
	padding-right: 10px;
}

.numInput {
	border:4px #68eee1 solid;
	padding: 10px 18px 10px 18px;
	font-family: 'Bebas Neue';
	font-size: 21pt;
	color: #384241;
	border-radius: 5px;
}

.minting-text-wallet {
	text-align: center;
	font-family: 'Bebas Neue';
	font-size: 16pt;
	color: #384241;
	margin-top: 40px;
}

.tooltiptext {
	visibility:hidden;
	width: 120px;
	background-color: #54e3de;
	color: #202828;
	text-align: center;
	padding: 5px;
	position:absolute;
	z-index: 1;
	border-radius: 5px;
	transform: translateX(10%);
}

.mint-btn-section:hover .tooltiptext {
	visibility: visible;
}

/* Connect Button */
.connect-button-section {
	text-align: center;
	display: grid;
    justify-content: center;
    align-items: center;
	padding-left: 50px;
}

button {
	/*background-image: url("./assets/images/connect-btn.png");*/
	background-color: #fccad6;
	border:4px #ff7aac solid;
	margin-left: 0px;
	border-radius: 5px;
	padding: 15px 25px 8px 20px;
	font-family: Filmotype;
	font-size: 25pt;
	color: #384241;
	filter: drop-shadow(3px 3px 0px #3a988f);
	cursor: pointer;
	transition: all .2s ease-in-out;
}

button:hover {
	transform: scale(1.2);
}

button:active {
	transform: translate(2px, 2px);
}

button:disabled {
	background-color: #516060;
	border:4px #374444 solid;
	color: #202828;
	transition:none;
	transform: none;
	filter: drop-shadow(3px 3px 0px #1c1c1c);
}

.scroll-down-section {
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
	padding-top: 10px;
}

.scroll-down-text {
	padding-bottom: 5px;
	color: #ff7aac;
	font-family: 'Bebas Neue';
	font-weight: 500;
	font-size: 20pt;
}

/* ABOUT SECTION */
.about {
	background-color: #68eee1;
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
}

.about-column-left {
	text-align: center;
	font-family: 'Bebas Neue';
	font-weight: 500;
	font-size: 40pt;
	line-height: 39pt;
	width: 100%;
	color: #fff;
	filter: drop-shadow(3px 3px 0px #3a988f);
	padding-right: 0%;
}

.about-column-right {
	text-align: left;
	width: 80%;
	font-size: 16pt;
	font-weight: 600;
	line-height: 20pt;
	padding-top: 20px;
}

/*Main ice cream description section */
.mainsections {
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
}

.stripe-divider {
	background: url("./assets/images/stripes.png");
	background-repeat: repeat-x;
	height: 70px;
}

.flavor-section {
	width: 90%;
	padding-top: 50px;
	padding-bottom: 50px;
}

.flavor-text {
	text-align: center;
	color: #ff7aac;
	font-family: 'Bebas Neue';
	font-weight: 500;
	font-size: 40pt;
	line-height: 39pt;
	padding-bottom: 30px;
}

.flavor-text-desc {
	text-align: center;
	font-size: 16pt;
	font-weight: 600;
	line-height: 20pt;
	padding-left: 5%;
	padding-right: 5%;
}

.ice-cream-row-container {
	width: 100%;
	height: 410px;
	overflow: hidden;
}

.ice-cream-holder {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icecream-row-images {
	height: 410px;
	width: auto;
	align-self: center;
}

.dot-rule {
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;
}

.horizontal-section-1 {
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
}

.section-1-image {
	text-align: center;
	padding-right: 0px;
}

.section-1-image img {
	width: 100%;
	height: auto;
}

.section-1-left {
	width: 100%;
	padding-top: 50px;
}

.section-1-header {
	color: #fdc754;
	font-family: 'Bebas Neue';
	font-weight: 500;
	font-size: 40pt;
	line-height: 39pt;
	padding-bottom: 20px;
	text-align: center;
}

.section-1-desc {
	font-size: 16pt;
	font-weight: 600;
	line-height: 20pt;
	text-align: center;
	padding-left: 10%;
	padding-right: 10%;
}

.horizontal-section-2 {
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
	padding-bottom: 50px;
}

.section-2-image {
	text-align: center;
	padding-left: 0px;
}

.section-2-image img {
	width: 100%;
	height: auto;
}

.section-2-left {
	width: 100%;
	padding-left: 0%;
}

.section-2-header {
	color: #68eee1;
	font-family: 'Bebas Neue';
	font-weight: 500;
	font-size: 40pt;
	line-height: 39pt;
	padding-bottom: 20px;
	text-align: center;
}

.section-2-desc {
	font-size: 16pt;
	font-weight: 600;
	line-height: 20pt;
	text-align: center;
	padding-left: 10%;
	padding-right: 10%;
	padding-bottom: 40px;
}

/* ROAD MAP*/
.roadmap {
	background-color: #ffcada;
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
	width: 100%;
}

.roadmap-header {
	text-align: center;
	font-family: 'Bebas Neue';
	font-weight: 500;
	font-size: 40pt;
	line-height: 39pt;
	width: 100%;
	color: #fff;
	filter: drop-shadow(3px 3px 0px #ff7aac);
	padding-bottom: 50px;
}

.the-launch {
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
	padding-left: 10%;
	padding-right: 10%;
	padding-bottom: 50px;
}

.launch-image {
	padding-right: 0px;
	padding-bottom: 30px;
}

.launch-text-header{
	font-family: Filmotype;
	font-size: 40pt;
	text-align: center;
}

.launch-text-desc {
	font-size: 16pt;
	font-weight: 600;
	line-height: 20pt;
	padding-right: 5%;
	padding-left: 5%;
}

.the-utility {
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
	padding-left: 10%;
	padding-right: 10%;
}

.utility-image {
	padding-right: 0px;
	padding-top: 30px;
	padding-bottom: 30px;
}

.utility-text-desc {
	font-size: 16pt;
	font-weight: 600;
	line-height: 20pt;
	padding-right: 5%;
	padding-left: 5%;
}

.about-the-team {
	background-color: #fed97e;
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
	padding-top: 50px;
	padding-bottom: 50px;
}

.team-title {
	text-align: center;
	font-family: 'Bebas Neue';
	font-weight: 500;
	font-size: 40pt;
	line-height: 39pt;
	width: 100%;
	color: #fff;
	filter: drop-shadow(4px 4px 0px #ec9c02);
	padding-bottom: 50px;
}

.team-subtitle {
	text-align: center;
	font-family: Filmotype;
	font-size: 40pt;
	padding-bottom: 50px;
	width: 90%;
}

.teamsection {
	display: flex;
  	justify-content: center;
	flex-direction: column;
}

.teamColumn {
	text-align: center;
	margin-left: 0px;
	margin-right: 0px;
	padding-bottom: 35px;
	width: 100%;
}

.teamColumn h3 {
	font-family: 'Bebas Neue';
	font-size: 30pt;
	line-height: 40px;
}

.teamColumn p {
	font-size: 18px;
	padding-bottom: 10px;
}

.cards {
	width: 200px;
	height: 200px;
	border-radius: 50%;
}

.copyright {
	height: 75px;
	background-color: #68eee1;
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: row;
}

.copyright-text {
	text-align: center;
	padding-left: 0px;
}

@media only screen and (min-width: 768px) {
	/* For tablet: */
	.col-s-1 {width: 8.33%;}
	.col-s-2 {width: 16.66%;}
	.col-s-3 {width: 25%;}
	.col-s-4 {width: 33.33%;}
	.col-s-5 {width: 41.66%;}
	.col-s-6 {width: 50%;}
	.col-s-7 {width: 58.33%;}
	.col-s-8 {width: 66.66%;}
	.col-s-9 {width: 75%;}
	.col-s-10 {width: 83.33%;}
	.col-s-11 {width: 91.66%;}
	.col-s-12 {width: 100%;}
	
	/* Ice Cream Truck Section */
	.icecream-truck-image {
		background: 
			url("./assets/images/truck.png") 50% top;
		width: 100%;
		height: 1023px;
		background-size: cover;
		background-repeat: no-repeat;
		margin-top: 0px;
	}
	
	.coming-soon-section {
		background: 
			url("./assets/images/truck-comingsoon.png") 50% top;
		width: 100%;
		height: 1023px;
		background-size: cover;
		background-repeat: no-repeat;
		margin-top: 0px;
	}

	.mint-section {
		width: 100%;
		height: 404px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 60px;
	}
	
	.minting-text {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 50px;
	}
	
	.minting-text-title {
		text-align: center;
		font-family: Filmotype;
		font-size: 35pt;
		color: #fff;
		filter: drop-shadow(3px 3px 0px #3a988f);
	}
	
	.minting-text-num {
		text-align: center;
		font-family: 'Bebas Neue';
		font-size: 15pt;
		color: #fccad6;
		filter: drop-shadow(2px 2px 0px #ff7aac);
	}
	
	.minting-text-price {
		text-align: center;
		font-family: 'Bebas Neue';
		font-size: 10pt;
		color: #fed97e;
	}

	.minting-text-wallet {
		text-align: center;
		font-family: 'Bebas Neue';
		font-size: 16pt;
		color: #384241;
		margin-top: 20px;
	}

	.minting-connect-text {
		text-align: center;
		font-family: 'Quicksand';
		font-size: 14pt;
		color: #fed97e;
		margin-bottom: 20px;
		margin-top: 30px;
	}
	
	.minting-inputs {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		padding-top: 10px;
	}
	
	.mint-num {
		text-align: left;
		padding-right: 10px;
	}
	
	.numInput {
		border:4px #68eee1 solid;
		padding: 10px 18px 10px 18px;
		font-family: 'Bebas Neue';
		font-size: 21pt;
		color: #384241;
		border-radius: 5px;
	}
	
	.section-1-image img {
		width: auto;
		height: auto;
	}
	
	.section-2-image img {
		width: auto;
		height: auto;
	}
}

	
@media only screen and (min-width: 1578px) {
	/* For desktop: */
	.col-1 {width: 8.33%;}
	.col-2 {width: 16.66%;}
	.col-3 {width: 25%;}
	.col-4 {width: 33.33%;}
	.col-5 {width: 41.66%;}
	.col-6 {width: 50%;}
	.col-7 {width: 58.33%;}
	.col-8 {width: 66.66%;}
	.col-9 {width: 75%;}
	.col-10 {width: 83.33%;}
	.col-11 {width: 91.66%;}
	.col-12 {width: 100%;}
	

	.icecream-truck-section {
		padding-bottom: 30px;
	}

	.icecream-truck-image {
		background: 
			url("./assets/images/truck-lg.png") 50% top;
		width: 100%;
		height: 1297px;
		background-size: cover;
		background-repeat: no-repeat;
		margin-top: 0px;
	}
	
	.coming-soon-section {
		background: 
			url("./assets/images/truck-lg-comingsoon.png") 50% top;
		width: 100%;
		height: 1744px;
		background-size: cover;
		background-repeat: no-repeat;
		margin-top: 0px;
	}

	.mint-section {
		width: 100%;
		height: 404px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 80px;
	}
	
	.minting-text {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 50px;
	}
	
	.minting-text-title {
		text-align: center;
		font-family: Filmotype;
		font-size: 45pt;
		color: #fff;
		filter: drop-shadow(3px 3px 0px #3a988f);
	}
	
	.minting-text-num {
		text-align: center;
		font-family: 'Bebas Neue';
		font-size: 30pt;
		color: #fccad6;
		filter: drop-shadow(3px 3px 0px #ff7aac);
	}
	
	.minting-text-price {
		text-align: center;
		font-family: 'Bebas Neue';
		font-size: 20pt;
		color: #fed97e;
	}
	
	.minting-inputs {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		padding-top: 10px;
	}
	
	.mint-num {
		text-align: left;
		padding-right: 10px;
	}
	
	.numInput {
		border:4px #68eee1 solid;
		padding: 10px 18px 10px 18px;
		font-family: 'Bebas Neue';
		font-size: 21pt;
		color: #384241;
		border-radius: 5px;
	}

	.minting-text-wallet {
		text-align: center;
		font-family: 'Bebas Neue';
		font-size: 16pt;
		color: #384241;
		margin-top: 18px;
	}
	
	/* Connect Button */
	.connect-button-section {
		margin-left: 0px;
		margin-top: 0px;
	}

	button {
		background-color: #fccad6;
		border:4px #ff7aac solid;
		margin-left: 0px;
		border-radius: 5px;
		padding: 15px 25px 8px 20px;
		font-family: Filmotype;
		font-size: 25pt;
		cursor: pointer;
		transition: all .2s ease-in-out;
	}

	button:hover {
		transform: scale(1.2);
	}

	button:active {
		transform: translate(2px, 2px);
	}

	button:disabled {
		background-color: #6b7676;
		border:4px #475454 solid;
		color: #202828;
		transition:none;
		transform: none;
		filter: drop-shadow(3px 3px 0px #1c1c1c);
	}
	
	/* ABOUT SECTION */
	.about {
		background-color: #68eee1;
		padding-top: 75px;
		padding-bottom: 75px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
	}

	.about-column-left {
		text-align: center;
		font-family: 'Bebas Neue';
		font-weight: 500;
		font-size: 80pt;
		line-height: 69pt;
		width: 30%;
		color: #fff;
		filter: drop-shadow(4px 4px 0px #3a988f);
		padding-right: 5%;
	}

	.about-column-right {
		text-align: left;
		width: 30%;
		font-size: 20pt;
		font-weight: 600;
		line-height: 26pt;
	}

	/*Main ice cream description section */
	.mainsections {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.stripe-divider {
		background: url("./assets/images/stripes.png");
		background-repeat: repeat-x;
		height: 70px;
	}

	.flavor-section {
		width: 50%;
		padding-top: 75px;
		padding-bottom: 50px;
	}

	.flavor-text {
		text-align: center;
		color: #ff7aac;
		font-family: 'Bebas Neue';
		font-weight: 500;
		font-size: 80pt;
		line-height: 69pt;
		padding-bottom: 30px;
	}

	.flavor-text-desc {
		text-align: center;
		font-size: 20pt;
		font-weight: 600;
		line-height: 26pt;
		padding-left: 25%;
		padding-right: 25%;
	}

	.dot-rule {
		text-align: center;
		padding-top: 75px;
		padding-bottom: 75px;
	}

	.horizontal-section-1 {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
	}

	.section-1-image {
		text-align: right;
		padding-right: 75px;
	}

	.section-1-left {
		width: 50%;
	}

	.section-1-header {
		color: #fdc754;
		font-family: 'Bebas Neue';
		font-weight: 500;
		font-size: 60pt;
		line-height: 49pt;
		padding-bottom: 20px;
		text-align: center;
	}

	.section-1-desc {
		font-size: 20pt;
		font-weight: 600;
		line-height: 26pt;
		text-align: center;
		padding-left: 5%;
		padding-right: 5%;
	}

	.horizontal-section-2 {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		padding-bottom: 75px;
	}

	.section-2-image {
		text-align: left;
		padding-left: 75px;
	}

	.section-2-left {
		width: 100%;
		padding-left: 45%;
	}

	.section-2-header {
		color: #68eee1;
		font-family: 'Bebas Neue';
		font-weight: 500;
		font-size: 60pt;
		line-height: 49pt;
		padding-bottom: 20px;
		text-align: center;
	}

	.section-2-desc {
		font-size: 20pt;
		font-weight: 600;
		line-height: 26pt;
		text-align: center;
		padding-left: 5%;
		padding-right: 5%;
	}

	/* ROAD MAP*/
	.roadmap {
		background-color: #ffcada;
		padding-top: 75px;
		padding-bottom: 75px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.roadmap-header {
		text-align: center;
		font-family: 'Bebas Neue';
		font-weight: 500;
		font-size: 80pt;
		line-height: 69pt;
		width: 30%;
		color: #fff;
		filter: drop-shadow(4px 4px 0px #ff7aac);
		padding-bottom: 50px;
	}

	.the-launch {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		padding-left: 20%;
		padding-right: 30%;
		padding-bottom: 50px;
	}

	.launch-image {
		padding-right: 50px;
	}

	.launch-text-header{
		font-family: Filmotype;
		font-size: 60pt;
		text-align: left;
		padding-left: 5%;
	}

	.launch-text-desc {
		font-size: 20pt;
		font-weight: 600;
		line-height: 26pt;
		padding-right: 25%;
	}

	.the-utility {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		padding-left: 35%;
		padding-right: 10%;
	}

	.utility-image {
		padding-right: 50px;
	}

	.utility-text-desc {
		font-size: 20pt;
		font-weight: 600;
		line-height: 26pt;
		padding-right: 35%;
	}

	.about-the-team {
		background-color: #fed97e;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding-top: 75px;
		padding-bottom: 75px;
	}

	.team-title {
		text-align: center;
		font-family: 'Bebas Neue';
		font-weight: 500;
		font-size: 80pt;
		line-height: 69pt;
		width: 30%;
		color: #fff;
		filter: drop-shadow(4px 4px 0px #ec9c02);
		padding-bottom: 50px;
	}

	.team-subtitle {
		text-align: center;
		font-family: Filmotype;
		font-size: 60pt;
		padding-bottom: 50px;
	}

	.teamsection {
		display: flex;
		justify-content: center;
		flex-direction: row;
	}

	.teamColumn {
		text-align: center;
		margin-left: 0px;
		margin-right: 0px;
		padding-bottom: 35px;
		width: 12%;
	}

	.teamColumn h3 {
		font-family: 'Bebas Neue';
		font-size: 30pt;
		line-height: 40px;
	}

	.teamColumn p {
		font-size: 18px;
		padding-bottom: 10px;
	}

}

/* dialog styles */

.ic-dialog .MuiDialogTitle-root {
  font-family: 'Filmotype';
  font-size: 80px;
  margin-bottom: 0px;
  color: #ffffff;
}

.ic-dialog-title-style {
	text-align: center;
	filter: drop-shadow(3px 3px 0px #3a988f);
}

.ic-dialog.ic-dialog-success .MuiDialogTitle-root {
  background: #68eee1;
}

.ic-dialog.ic-dialog-info .MuiDialogTitle-root {
  background: #fed97e;
}

.ic-dialog.ic-dialog-error .MuiDialogTitle-root {
  background: pink;
}

.ic-dialog .MuiDialogContent-root {
}

.ic-dialog .MuiDialogContentText-root {
  font-family: 'Quicksand';
  font-size: 20pt;
  font-weight: 600;
  line-height: 30pt;
  text-align: center;
}

.ic-dialog-text-style {
	padding-left: 30px;
	padding-right: 30px;
}

.ic-dialog-button {
	padding-top: 20px;
	padding-bottom: 30px;
}
@media only screen and (min-width: 2025px) {
	/* For desktop: */
	.col-1 {width: 8.33%;}
	.col-2 {width: 16.66%;}
	.col-3 {width: 25%;}
	.col-4 {width: 33.33%;}
	.col-5 {width: 41.66%;}
	.col-6 {width: 50%;}
	.col-7 {width: 58.33%;}
	.col-8 {width: 66.66%;}
	.col-9 {width: 75%;}
	.col-10 {width: 83.33%;}
	.col-11 {width: 91.66%;}
	.col-12 {width: 100%;}

	.icecream-truck-image {
		background: 
			url("./assets/images/truck-xl.png") 50% top;
		width: 100%;
		height: 1744px;
		background-size: cover;
		background-repeat: no-repeat;
		margin-top: 0px;
	}

	.minting-text {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 100px;
	}

	.minting-text-title {
		text-align: center;
		font-family: Filmotype;
		font-size: 55pt;
		color: #fff;
		filter: drop-shadow(3px 3px 0px #3a988f);
	}

	.minting-text-wallet {
		text-align: center;
		font-family: 'Bebas Neue';
		font-size: 16pt;
		color: #384241;
		margin-top: 80px;
	}
}